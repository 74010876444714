.logo {
  width: 6.3rem;
  height: 6.3rem;
  object-fit: cover;
  transition: all .7s ease-out; 
}

.nav-icon-contact {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  /* margin: 1rem; */
}

.navbar {
  height: 8rem; 
  width: 100vw;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  z-index: 3;
  background: rgb(30, 30, 30);
}

.nav-list-item {
  font-size: 1.2rem;
  margin: 1rem;
  cursor: pointer;
}

.nav-list-item:hover {
  color: #466cb3;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #466cb3;
  transition: all .2s ease-out;
}

.nav-contact-btn {
  border: none;
  font-weight: bold;
  background: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 1rem;
  height: 2.5rem;
  font-size: 1rem;
}

.logo:hover {
  transition: all .3s ease-out; 
  transform: rotate(360deg);
}

.nav-contact-btn:hover {
  transition: all .2s;
  background: #466cb3;
  cursor: pointer;
  color:white;
}

.nav-icon-contact:hover {
  color: white !important;
}

.active {
  color: #466cb3;
  border-bottom: 3px solid #466cb3;
  padding-bottom: .5rem;
}

.nav-contact-btn .active {
  background: #466cb3;
  color: white;
}

.hamburger-menu {
  display: none;
  object-fit: cover;
  height: 1.8rem;
}

.nav-hamburger-menu {
  position: absolute;
  top: 6rem;
  right: 1rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: .5rem;
  height: fit-content;
  min-width: 15rem;
  background: rgb(40, 40, 40);
  border-radius: 1rem;
}

.ham-list-item {
  padding: .5rem 3rem;
  margin: 0.25rem;
  background: rgb(30, 30, 30);
}


@media screen and (max-width: 700px) {
  .navbar {
    height: 6.5rem;
  }

  .logo {
    width: 5rem;
    height: 5rem;
  }

  .hamburger-menu {
    display: flex;
    width: 2rem;
    height: 2rem;
  }

  .menu, .nav-contact-btn {
    display: none;
  }

  .nav-hamburger-menu {
    min-width: 10rem; 
  }

  .ham-list-item {
    font-size: .9rem;
    border-radius: 1rem;

  } 
}

/* Need to add hamburger menu button and media queries */