#about {
  background-color: white;
  color: black;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-content-grid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr 1fr;
  /* grid-template-rows: 1fr 2fr .2fr; */
  grid-template-areas:
    ". about . skills ."
    ". about . skills .";
  gap: 1rem;
  align-items: center;
}

.aboutMe-text {
  grid-area: about;
  grid-row: 2;
  margin-top: 1rem;
}

.aboutMe-title {
  margin-bottom: .5rem;
}

.aboutMe-para-first,
.aboutMe-para-second,
.aboutMe-para-third {
  letter-spacing: 1px;
}

.aboutMe-para-first,
.aboutMe-para-second {
  margin-bottom: 1rem;
}


.skills-icons-container {
  grid-area: skills;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.skills-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.about-skill {
  width: 5rem;
  height: 5rem;
}

@media screen and (max-width: 700px) {
  #about {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  }

  .about-content-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 550px) {
  .about-skill {
    width: 3.8rem;
    height: 3.8rem;
  }
  #about {
    min-height: 100vh;
    margin-bottom: 1rem;
  }
  .about-content-grid {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 440px) {
  .about-skill {
    width: 2.7rem;
    height: 2.7rem;
  }

  .aboutMe-title {
    font-size: 1rem;
  }

  #about {
    min-height: 100vh;
    margin-bottom: 1rem;
  }

  .about-content-grid {
   
    margin-bottom: 1rem;
  }
}
