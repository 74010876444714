#projects {
  min-height: 100vh;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
}

.projects-text {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  margin-top: 7rem;
}

.project-images-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 60%;
  margin: 0 auto;
  justify-content: center;
}


.project-card {
  -webkit-box-shadow: inset 0px 0px 36px 14px rgba(70,108,179,1);
  -moz-box-shadow: inset 0px 0px 36px 14px rgba(70,108,179,1);
  box-shadow: inset 0px 0px 36px 14px rgba(70,108,179,1);
  padding: .8rem;
}
.project-card:hover {
  -webkit-box-shadow: 0px 0px 36px 14px rgba(70,108,179,1);
  -moz-box-shadow: 0px 0px 36px 14px rgba(70,108,179,1);
  box-shadow: 0px 0px 30px 14px rgba(70,108,179,1);
}

.project-img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.project-live-repo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.live, .repo {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
}

.url {
  margin-bottom: 1rem;
}

.live:hover, .repo:hover {
  transition: all .2s ease-in-out;
  color: #466cb3;
}

.project-title {
  font-size: 1.2rem;
  font-weight: bold;
  align-self: flex-start;
  text-decoration: underline;
  margin-bottom: .3rem;
}

.see-more-btn {
  /* margin: 1rem 0; */
  padding: 0.5rem 1.8rem;
  border: none;
  border-radius: 1rem;
  background: white;
  font-size: 1rem;
  transition: all .5s;
  font-size: 1rem;
}

.see-more-btn:hover {
  background-color: #466cb3;
  transition: all .3s;
  color: white;
  cursor: pointer;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1200px) {
  .project-images-container {
    max-width: 80%;
  }

  .project-card {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .project-images-container {
    grid-template-columns: 1fr 1fr;
  }

  .project-card {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .projects-text {
    font-size: 1.2rem;
    margin-top: 5rem;
  }

  .project-images-container {
    grid-template-columns: 1fr;
    max-width: 90%;
  }

  .live, .repo {
    text-decoration: none;
    color: white;
    font-size: 1.1rem;
  }

  .project-card {
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .project-img {
    width: 100%;
    border-radius: 0.5rem;
  }
}

@media screen and (max-width: 500px) {
  .projects-text {
    font-size: 1rem;
    margin-top: 4rem;
  }

  .live, .repo {
    font-size: 1rem;
  }

  .see-more-btn {
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 440px) {
  .projects-text {
    font-size: 0.9rem;
    margin-top: 3rem;
  }

  .live, .repo {
    font-size: 0.9rem;
  }

  .see-more-btn {
    padding: 0.4rem 1.2rem;
    font-size: 0.8rem;
  }
}

  
