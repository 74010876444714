.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.devicon-github-original, .devicon-linkedin-plain {
  font-size: 2rem;
  transition: all .3s ease-in-out;
}

.github-link:hover .devicon-github-original, .linkedIn-link:hover .devicon-linkedin-plain {
  transform: scale(1.3); 
}